import React from 'react'
import LondonTemplate from '../../../templates/LondonTemplate'

const LondonLocation = () => {
    return (
        <LondonTemplate>
            <div className="salesforce-footer"></div>
            <div className="heroContent">
                <div className="heroSky">
                    <div className="hottieCenter">
                    <img className="herohottie" src="https://developer.salesforce.com/resources2/bootcamps2022/London/Images/hero/2023_BC_Regional-Landing-Page_Hootie.gif" alt="background" />
                    </div>
                    <img className="heroballon" src="https://developer.salesforce.com/resources2/bootcamps2022/London/Images/hero/2023_BC_Regional-Landing-Page_UK-Balloon.gif" alt="background" />
                </div>
            </div>
            <section className="container-content">
                <h1 className="h1Internal">Location</h1>
                <h3>Set your GPS.</h3>

                <div className="hotel-holder"></div>
                <p>Trailblazer Bootcamp will take place at the</p> 
                <p><a href="https://www.parkplazariverbank.com/" target="_blank">Park Plaza London Riverbank</a>.</p>
            </section>

            <section className="container-content">
                <div className="center">
                    <img className="logo logo-loc logoInternal" src="https://developer.salesforce.com/resources2/bootcamps2022/London/Images/home/2023-Bootcamp-Seal_London_Color.png"/>
                </div>
            </section>

            <div className="characters schedule-bg">
                <img src="https://developer.salesforce.com/resources2/bootcamps2022/London/Images/location/Location.png" /> 
            </div>
        </LondonTemplate>
    )
}

export default LondonLocation
